<template>

  <div>
    <!-------------------------------------->
    <!-- Adsense Right Column             -->
    <!-------------------------------------->
    <v-sheet
      v-if="$root.currentUser === null || !$root.currentUser.premium"
      class="rounded-lg mt-4 justify-start align-start"
      style="position: relative !important; flex: 1; max-height: fit-content"
      color="transparent"
    >

<!--          <v-card-->
<!--            :key="`adsense_ad_vertical_${selectedAd}`"-->
<!--            class="my-2 rounded-lg"-->
<!--            height="250" style="z-index: 99"-->
<!--            width="300" flat color="transparent">-->

<!--            <adsense ad-slot="6844584728"-->
<!--                     style="display:inline-block;width:300px;height:250px;z-index: 99"-->
<!--            ></adsense>-->

<!--          </v-card>-->

      <a href="https://crewly.es/"
         target="_blank"
         @click="clickStats(4)"
        style="z-index: 99"
        class="rounded-lg">

        <v-carousel
          cycle
          hide-delimiters
          :show-arrows="false"
          class="rounded-lg"
          style="width: 100%; height: 100%; cursor: pointer"
          :interval="12000"
          height="auto"
        >
          <v-carousel-item
            src="https://examenselectivitat.cat:3000/api/images/other/crewly-ad-1.webp"></v-carousel-item>
          <v-carousel-item
            src="https://examenselectivitat.cat:3000/api/images/other/crewly-ad-2.webp"></v-carousel-item>
          <v-carousel-item
            src="https://examenselectivitat.cat:3000/api/images/other/crewly-ad-3.webp"></v-carousel-item>
          <v-carousel-item
            src="https://examenselectivitat.cat:3000/api/images/other/crewly-ad-4.webp"></v-carousel-item>
        </v-carousel>

      </a>

      <div v-if="showAd && false" style="z-index: 100" class="mt-10">
        <div
          v-if="selectedProblem % 2 === 0 || true"
          @click="$router.push({path: `/dossiers-selectivitat`})"
          style="z-index: 99"
          class="rounded-lg">

          <v-carousel
            cycle
            hide-delimiters
            :show-arrows="false"
            class="rounded-lg"
            style="width: 100%; height: 100%; cursor: pointer"
            :interval="12000"
            height="auto"
          >
            <v-carousel-item
              src="https://examenselectivitat.cat:3000/api/images/other/dossiers-sele1.png"></v-carousel-item>
            <v-carousel-item
             src="https://examenselectivitat.cat:3000/api/images/other/Screenshot 2023-05-11 at 22.25.17.png"></v-carousel-item>
            <v-carousel-item
              src="https://examenselectivitat.cat:3000/api/images/other/Screenshot 2023-05-11 at 22.25.53.png"></v-carousel-item>
          </v-carousel>

        </div>

        <v-img
          v-else
          @click="$router.push({path: '/premium'})"
          class="rounded-lg mt-5"
          height="100%" style="cursor: pointer"
          src="https://examenselectivitat.cat:3000/api/images/other/no_ads.png"></v-img>


        <v-icon
          v-if="false"
          @click="showAd=false"
          style="position: absolute; top: 15px; right: 15px; z-index: 99"
          color="white" small
        >
          {{ mdiClose() }}
        </v-icon>
      </div>


<!--      <v-hover v-slot:default="{hover}">-->

<!--        <v-card-->
<!--          border="left"-->
<!--          :color="color"-->
<!--          style="cursor:pointer;"-->
<!--          dark flat-->
<!--          class="d-flex flex-column align-center justify-center text-center py-6 rounded-lg mt-3"-->
<!--        >-->
<!--          <span-->
<!--            class="font-rubik font-weight-medium"-->
<!--            style="font-size: 14px"-->
<!--          >-->
<!--            Estudia <span>sense anuncis</span> per només <span class="font-weight-medium">2,49€</span>.-->
<!--          </span>-->

<!--          <v-btn-->
<!--            @click="$router.push({path: '/premium'})"-->
<!--            :ripple="false" depressed-->
<!--            color="white"-->
<!--            style="letter-spacing: normal; border: 2px solid #FFA204 !important; color: #256EFF"-->
<!--            class="text-none font-weight-bold font-rubik rounded-lg px-3 mt-3"-->
<!--          >-->
<!--            Veure promoció-->
<!--          </v-btn>-->
<!--        </v-card>-->

<!--      </v-hover>-->
    </v-sheet>


  </div>
</template>

<script>
import Adsense from "../../../../components/anuncis/Adsense.vue";
import {mdiClose} from "/src/assets/mdi.json";

export default {
  name: "AnuncisVerticals",
  components: {
    Adsense
  },
  props: {
    color: String,
    selectedProblem: Number
  },
  data() {
    return {
      selectedAd: 0,
      showAd: true,
      examsPeriod: false,
    }
  },
  mounted() {
    this.randomStart = this.selectedAd;
    setInterval(() => {
      this.selectedAd++;
      // if (this.selectedAd > 3) {
      //   this.selectedAd = 0;
      // }
    }, 1000 * 30)
    // }, 1000 *10 )
  },
  methods: {
    async clickStats(ads_id) {
      await this.axios.post("/ad-update/click", {
          ads_id: ads_id
      })
    },
    mdiClose() {
      return mdiClose
    },
    randomStart() {
      const random = Math.random();
      if (random < 0.25) return 0;
      else if (random < 0.25) return 1;
      else if (random < 0.25) return 2;
      else return 3;
    }
  },
  watch: {
    '$refs.video'(val) {
      if (val) {
        this.$refs.video.muted = true;

        this.$refs.video.play();
      }
    },
  }
}
</script>

<style scoped>

</style>
